module.exports={
  "Loading": "Loading",
  "Till startsidan": "Till startsidan",
  "Hoppsan": "Hoppsan",
  "startsidan": "startsidan",
  "HÃ¤r fanns det visst ingen sida. Kanske kan du hitta vad du sÃ¶ker i menyn eller via %s.": "HÃ¤r fanns det visst ingen sida. Kanske kan du hitta vad du sÃ¶ker i menyn eller via %s.",
  "fÃ¶rsÃ¶ker igen": "fÃ¶rsÃ¶ker igen",
  "NÃ¥got har visst gÃ¥tt galet. Det kan vara tillfÃ¤lligt och fungera om du %s.": "NÃ¥got har visst gÃ¥tt galet, eller sÃ¥ finns inte sidan. Det kan vara tillfÃ¤lligt och fungera om du %s.",
  "Logga in": "Logga in",
  "Ange din kod": "Ange din kod",
  "Kontakt": "Kontakt",
  "Ãrskurs %s": "Ãrskurs %s",
  "TrÃ¥den %s Ã¤r framtagen primÃ¤rt fÃ¶r mÃ¥l %s": "TrÃ¥den %s Ã¤r framtagen primÃ¤rt fÃ¶r mÃ¥l %s",
  "Arbetspass": "Arbetspass",
  "Ha tillgÃ¤ngligt": "Ha tillgÃ¤ngligt",
  "Material": "Material",
  "GÃ¶r sÃ¥ hÃ¤r": "GÃ¶r sÃ¥ hÃ¤r",
  "Till dig som pedagog": "Till dig som pedagog",
  "Logga in med din kod": "Logga in med din kod",
  "AnvÃ¤ndarvillkor": "AnvÃ¤ndarvillkor",
  "Det funkade inte. Skrev du rÃ¤tt kod?": "Det funkade inte. Skrev du rÃ¤tt kod?",
  "Se utmaning": "Se utmaning",
  "Utmaningar": "Utmaningar",
  "Tillbaka": "Tillbaka",
  "Vanliga frÃ¥gor": "Vanliga frÃ¥gor",
  "Inspiration": "Inspiration",
  "Spela %s": "Spela %s",
  "Visa introduktion": "Visa introduktion",
  "Inledning": "Inledning",
  "%s och mÃ¥l %s": "%s och mÃ¥l %s",
  "LÃ¤s mer": "LÃ¤s mer",
  "Feedback": "Feedback",
  "Faktan": "Faktan",
  "InfÃ¶r arbetspassen": "InfÃ¶r arbetspassen",
  "FÃ¶rberedelser": "FÃ¶rberedelser",
  "Introduktion": "Introduktion",
  "Visa mer": "Visa mer",
  "Tillbaka till start": "Tillbaka till start",
  "Sign out": "Logga ut",
  "Grade %s": "Ãrskurs %s",
  "DÃ¶lj info": "DÃ¶lj info",
  "Globala mÃ¥len": "Globala mÃ¥len",
  "MÃ¥l": "MÃ¥l",
  "LÃ¤roplan": "LÃ¤roplan",
  "Koppling till lÃ¤roplanen": "Koppling till lÃ¤roplanen",
  "Moment": "Moment",
  "Introdukasdfaftion": "Introdukasdfaftion",
  "Fakta": "Fakta",
  "Material fÃ¶r nedladdning": "Material fÃ¶r nedladdning",
  "Ladda ner materialet": "Ladda ner materialet",
  "Allt du behÃ¶ver, redo att skriva ut.": "Allt du behÃ¶ver, redo att skriva ut.",
  "LÃ¤rarintroduktion": "LÃ¤rarintroduktion",
  "Koden kÃ¤nns inte igen. Skrev du rÃ¤tt?": "Koden kÃ¤nns inte igen. Skrev du rÃ¤tt?",
  "BÃ¶rja hÃ¤r": "BÃ¶rja hÃ¤r",
  "Material & media": "Material & media",
  "spilloteket.se": "spilloteket.se"
}